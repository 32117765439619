.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.switchToggle {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 34px;
}

.switchToggle input {display:none;}

.sliderToggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c7c7c7;
  -webkit-transition: .4s;
  transition: .4s;
}

.sliderToggle:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .sliderToggle {
  background-color: #2a68b9;
}

input:focus + .sliderToggle {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .sliderToggle:before {
  -webkit-transform: translateX(55px);
  -ms-transform: translateX(55px);
  transform: translateX(55px);
}

/*------ ADDED CSS ---------*/
.onToggle
{
  display: none;
}

.onToggle, .offToggle
{
  color: white;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked+ .sliderToggle .onToggle
{display: block;}

input:checked + .sliderToggle .offToggle
{display: none;}

/*--------- END --------*/

/* Rounded sliders */
.sliderToggle.roundToggle {
  border-radius: 34px;
}

.sliderToggle.roundToggle:before {
  border-radius: 50%;}




