/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.side-nav hr {
  margin: initial;
}

.MuiCollapse-wrapperInner {
  background-color: #ffffff;
}

.side-nav .nav-item:hover .nav-link,
.side-nav .nav-link {
  font-weight: normal;
  transition: initial;
}

.api-tittle {
  text-decoration: underline;
  text-underline-offset: 7px;
}
